<template>
    <div>
        <v-dialog v-model="descriptionDialog"  width="380px">
            <v-card class="d-flex flex-column overflow-hidden overflow-y-hidden px-5">
                <v-icon @click="descriptionDialog = false" class="align-self-end px-5 py-5"
                color="teal">mdi-close</v-icon>
                <v-img
                    alt="descripción"
                    class="mt-1 text-center mx-auto"
                    contain
                    :style="{ borderRadius: '20px' }"
                    :src="require(`../../assets/descriptions/${title}.png`)"
                    max-width="100%"
                />
                <p class="title-description font-weight-bold text-center mt-2 mb-1">{{title}}</p>
                <p class="body-2 text-center px-5 py-1"> {{content}}
                </p>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
  name: 'description_service_modal',
  props: {
    value: Boolean,
    title: String,
    content: String,
  },
  computed: {
    descriptionDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    getImgUrl() {
      return `../../assets/descriptions/${this.title}.png`;
    },
  },
};
</script>

<style>
.v-dialog {
    border-radius: 18px !important;
}
.title-description {
    color: rgba(0, 40, 133, 1);
}
</style>
